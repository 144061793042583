
import { Directive, HostListener, Renderer2 } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[formControlName][appPhoneMask]',
})
export class PhoneMaskDirective {
  constructor(private ngControl: NgControl, private renderer: Renderer2) { }

  @HostListener('ngModelChange', ['$event'])
  onModelChange(event: string): void {
    this.updateInputValue(event);
  }

  @HostListener('keydown.backspace', ['$event'])
  handleBackspace(event: KeyboardEvent): void {
    const inputValue = (event.target as HTMLInputElement).value;
    this.updateInputValue(inputValue, true);
  }

  private updateInputValue(input: string, backspace: boolean = false): void {

    if (input === null) {
      return;
    }
    // Remove all non-digit characters
    let cleanedValue = input.replace(/\D/g, '');

    // Adjust for backspace
    if (backspace && cleanedValue.length <= 6) {
      cleanedValue = cleanedValue.slice(0, -1);
    }

    // Format the cleaned value
    let formattedValue = this.formatPhoneNumber(cleanedValue);

    if (this.ngControl.control && this.ngControl.control.value !== formattedValue) {
      // Write the formatted value to the control
      this.ngControl.control?.setValue(formattedValue);
    }
    this.ngControl.control?.updateValueAndValidity(); 
  }

  private formatPhoneNumber(value: string): string {
    if (value.length === 0) {
      return '';
    } else if (value.length <= 3) {
      return value; // Return as-is for 1-3 digits
    } else if (value.length <= 6) {
      return `${value.slice(0, 3)}-${value.slice(3)}`; // Format as 111-111
    } else {
      return `${value.slice(0, 3)}-${value.slice(3, 6)}-${value.slice(6, 10)}`; // Format as 111-111-1111
    }
  }
}

