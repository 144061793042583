import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';


@Injectable({
  providedIn: 'root'
})
export class TranslationService {
  private subscriptionKey = environment.translationApiKey;
  private endpoint = environment.translationEndpoint;

  constructor(private http: HttpClient) { }

  translate(requestBody: { Text: string }[], targetLanguage: string): Observable<any> {
    const url = `${this.endpoint}/translator/text/v3.0/translate?api-version=3.0&to=${targetLanguage}`;
    const headers = new HttpHeaders({
      'Ocp-Apim-Subscription-Key': this.subscriptionKey,
      'Ocp-Apim-Subscription-Region': 'westus3',
      'Content-Type': 'application/json',
      'apitype': 'translation'
    });
    return this.http.post<any>(url, requestBody, { headers });
  }

  updateLanguage(code: string) {
    if (code !== 'EN') {
      const elements = document.body.querySelectorAll('*');
      const dropdownArea = document.getElementById('languageDropdown');

      const textNodes: string[] = [];
      const dynamicElements: Node[] = [];

      elements.forEach((element) => {
        if (element !== dropdownArea && !dropdownArea?.contains(element)) {
          element.childNodes.forEach((child) => {
            if (child.nodeType === Node.TEXT_NODE) {
              const textToTranslate = child.textContent?.trim() || '';
              if (textToTranslate) {
                const parentElement = child.parentElement;
                if (parentElement && parentElement.getAttribute('data-translated') !== code) {
                  textNodes.push(textToTranslate);
                  dynamicElements.push(child);
                  parentElement.setAttribute('data-translated', code);
                }
              }
            }
          });
        }
      });

      if (textNodes.length > 0) {
        const maxBatchSize = 25; // Azure API limit
        const batches: string[][] = [];
        for (let i = 0; i < textNodes.length; i += maxBatchSize) {
          batches.push(textNodes.slice(i, i + maxBatchSize));
        }
        //let batchCallCount = 0;
        batches.forEach((batch) => {
          const requestBody = batch.map((text) => ({ Text: text }));

          //batchCallCount++; 
          //console.log(`Calling API for batch #${batchCallCount}:`, requestBody);

          this.translate(requestBody, code).subscribe(
            (response: any) => {
              if (response && response.length > 0) {
                const translationMap = new Map<string, string>();
                response.forEach((res: any, index: number) => {
                  translationMap.set(batch[index], res.translations[0].text);
                });

                dynamicElements.forEach((el, idx) => {
                  if (translationMap.has(textNodes[idx])) {
                    el.textContent = translationMap.get(textNodes[idx])!;
                  }
                });
              } else {
                console.error('Unexpected response format:', response);
              }
            },
            (error) => {
              console.error('Translation error:', error);
            }
          );
        });
      }
    }
  }

}
