import { Component, Output, EventEmitter, Input, ChangeDetectorRef, HostListener } from '@angular/core';
import { environment } from '../../../environments/environment';
import { AppConstants } from '../../appconstants';
import { AuthService } from 'src/app/services/authguard/auth.service';
import { ProfileService } from 'src/app/services/profile.service';
import { MsalService } from '@azure/msal-angular';
import { AppMonitorService } from 'src/app/services/app-monitor.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Language } from '../../model/Language';
import { TranslationService } from '../../services/translation.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrl: './nav-bar.component.css'
})
export class NavBarComponent {
  isResourceSubmenuVisible = false;
  isUsersMenuOpen = false;
  isJobsMenuOpen = false;
  isCandidateSearchMenuOpen = false;
  protected basePath = environment.umbracoBaseUri + "/";
  @Output() loginClicked: EventEmitter<void> = new EventEmitter<void>();
  @Input() CommonContent: any;
  userRole = '';
  jobSeeker = AppConstants.JOBSEEKER;
  employer = AppConstants.EMPLOYER;
  lawaStaff = AppConstants.LAWASTAFF;
  commPartner = AppConstants.COMMPARTNER;
  isLoggedIn: boolean = false;
  isApproved: boolean = false;
  isProfileStatusCompleted: boolean = true;
  constructor(
    private auth: AuthService,
    private cdr: ChangeDetectorRef,
    private profileService: ProfileService,
    private authService: MsalService,
    private appMonitorService: AppMonitorService,
    private translationService: TranslationService,
  ) {
  }

  languages: Language[] = [
    { name: 'English', code: 'EN' },
    { name: 'Spanish', code: 'ES' },
    { name: 'Chinese', code: 'ZH' },
    { name: 'Arabic', code: 'AR' },
    { name: 'French', code: 'FR' },
    { name: 'Filipino', code: 'FIL' },
    { name: 'Armenian', code: 'HY' },
    { name: 'Korean', code: 'KO' },
    { name: 'Persian', code: 'FA' },
    { name: 'Vietnamese', code: 'VI' },
    { name: 'Russian', code: 'RU' },
    { name: 'Japanese', code: 'JA' },
    { name: 'Samoan', code: 'SM' },
    { name: 'Hindi', code: 'HI' },
    { name: 'Hebrew', code: 'HE' },
    { name: 'German', code: 'DE' }
  ];
  
  selectedLanguage: Language = this.languages[0];

  ngOnInit(): void {
    sessionStorage.setItem('languageselected', this.selectedLanguage.code);
    this.auth.currentUserStatus.subscribe((status: boolean) => {
      const loginStatus = localStorage.getItem(AppConstants.LOGINSTATUS);

      if (loginStatus) {
        this.isLoggedIn = loginStatus == AppConstants.TRUE ? true : false;
      } else {
        this.isLoggedIn = status;
      }

      const user = localStorage.getItem(AppConstants.USER);
      if (user) {
        this.userRole = JSON.parse(user).role;
        this.isProfileStatusCompleted = this.profileStatusCompleted(user);
        this.isApprovedEmployer();
      };


    });
    
    this.cdr.detectChanges();
  }


  onLoginClicked(): void {
    this.loginClicked.emit(); // Emit the event when the button is clicked
  }
  toggleResourceSubmenu(event: MouseEvent) {
    event.stopPropagation();
    this.isResourceSubmenuVisible = !this.isResourceSubmenuVisible;
    this.isCandidateSearchMenuOpen = false;
  }
  toggleUsersMenu(event: MouseEvent) {
    event.stopPropagation();
    this.isUsersMenuOpen = !this.isUsersMenuOpen;
  }
  toggleJobMenu(event: MouseEvent){
    event.stopPropagation();
    this.isJobsMenuOpen = !this.isJobsMenuOpen;
  }
  toggleCandidateSearchSubmenu(event: MouseEvent) {
    event.stopPropagation();
    this.isCandidateSearchMenuOpen = !this.isCandidateSearchMenuOpen;
    this.isResourceSubmenuVisible = false;
  }

  isApprovedEmployer() {
      let user = this.profileService.getUserClaims();
      if (!user || user == null) return;
      const isEmployer = user?.role === AppConstants.EMPLOYER;
      const isStaff = user?.role === AppConstants.LAWASTAFF;
      const isCBO = user?.role === AppConstants.COMMPARTNER;

      if (isCBO) return;
      if (isStaff) this.isApproved = true
      else if (isEmployer && user.isApproved) this.isApproved = true
  }

  profileStatusCompleted(user: any): boolean{
    return JSON.parse(user).profileCompletionStatus;
  }
  private invalidUser(status: any) {
    localStorage.setItem('userStatus', status);
    this.logout();
  }
  logout() {
    this.appMonitorService.clearUserContext();
    localStorage.removeItem('Access-Token-Azure-B2C');
    localStorage.removeItem(AppConstants.USER);
    localStorage.removeItem(AppConstants.EMPLOYER);
    localStorage.setItem(AppConstants.LOGINSTATUS, AppConstants.FALSE);
    this.auth.loggedIn.next(false);
    this.authService.logout();
  }
  
  changeLanguage(language: Language) {
    this.selectedLanguage = language;
    sessionStorage.setItem('languageselected', this.selectedLanguage.code);
    if(this.selectedLanguage.code == 'EN'){
      location.reload();
    } else {
      this.translationService.updateLanguage(language.code);
    }    
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedInsideCandidateSearch = target.closest('.nav-item-candidate-search');
    const clickedInsideResource = target.closest('.nav-item-resource');
    const clickedInsideUsers = target.closest('.nav-item-user');
    const clickedInsideJobMenu = target.closest('.nav-item-job-menu');

    if (!clickedInsideCandidateSearch) {
      this.isCandidateSearchMenuOpen = false;
    }
    if (!clickedInsideResource) {
      this.isResourceSubmenuVisible = false;
    }
    if (!clickedInsideUsers) {
      this.isUsersMenuOpen = false;
    }
    if (!clickedInsideJobMenu) {
      this.isJobsMenuOpen = false;
    }
  }
}
